
*{
  box-sizing: border-box;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .bg-primary {
    @apply bg-gradient-to-r from-white to-sky-300 dark:from-black dark:to-sky-700 text-sky-950 dark:text-white;
  }

  .btn-primary {
    @apply w-full min-w-max bg-sky-400 dark:bg-sky-600 dark:text-gray-100 p-4 rounded-full font-semibold hover:bg-sky-500 hover:dark:bg-sky-700 disabled:opacity-75 transition-all;
  }

  .btn-secondary {
    @apply h-12 p-2 border rounded-lg text-sm font-semibold hover:bg-gray-200 dark:hover:bg-gray-800 dark:text-gray-100;
  }

  .sidebar {
    @apply flex relative lg:sticky w-full lg:w-56 lg:top-28 lg:left-0 lg:mb-28 rounded-lg bg-gray-100 dark:text-gray-100 dark:bg-gray-700 ;
  }

  .topbar {
    @apply flex relative w-full rounded-lg bg-gray-100 dark:text-gray-100 dark:bg-gray-700 ;
  }

  .form-control {
    @apply w-full flex flex-col gap-2;
  }

  .form-row {
    @apply w-full grid grid-flow-col justify-stretch space-x-4;
  }

  .placeholder {
    @apply w-full h-10 bg-gray-200 rounded-md animate-pulse;
  }

  .inputDarkModeOverride:-webkit-autofill,
  .inputDarkModeOverride:-webkit-autofill:hover,
  .inputDarkModeOverride:-webkit-autofill:focus,
  .inputDarkModeOverride:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #374151 inset;
    -webkit-text-fill-color: white !important;
  }

  .inputOverride:-webkit-autofill,
  .inputOverride:-webkit-autofill:hover,
  .inputOverride:-webkit-autofill:focus,
  .inputOverride:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #ffffff inset;
    -webkit-text-fill-color: #374151 !important;
  }
}
